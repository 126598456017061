<template>
  <div>
    <div class="top_title">
      <div>岗位设置</div>
    </div>
    <div class="mid_boxs">
      <div class="mid_box_left_">
        <div class="mid_box_left_top">
          <span> 公司： </span>
          <el-select
            class="sbtn"
            v-model="Cpy"
            @change="getDept"
            placeholder="请选择"
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mid_box_left_bt" id="table" :style="{ height: elementHeight + 'px' }">
          <div class="mid_box_left_bt_top">
            <span>部门设置</span>
          </div>
          <el-tree
            ref="tree"
            :data="deptdata"
            :props="defaultProps1"
            accordion
            node-key="id"
            @node-click="handleNodeClick1"
            highlight-current
          >
          </el-tree>
        </div>
      </div>
      <div class="mid_box_right_">
        <el-button type="primary" @click="toAdd" style="margin: 2% 0"
          >新增岗位</el-button
        >
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            background: '#E8EBF4',
            color: '#000000',
            'border-top': '1px solid #DFDFDF',
            'border-right': '1px solid #DFDFDF',
          }"
          :height="elementHeight"
          id="table"
        >
          <el-table-column prop="postName" label="岗位名称" width="180">
          </el-table-column>
          <el-table-column prop="postNo" label="岗位编号" width="180">
          </el-table-column>
          <!-- <el-table-column prop="" label="岗位负责人"> </el-table-column> -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i
                id="i"
                class="el-icon-delete"
                @click="deleteItem(scope.row)"
              ></i>
              <i id="i" class="el-icon-edit" @click="editItem(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog title="新增岗位" :visible.sync="dialogVisible" width="20%">
      <el-form label-width="80px" :model="formLabelAlign1">
        <el-form-item label="岗位名称">
          <el-input v-model="formLabelAlign1.postName"></el-input>
        </el-form-item>
        <el-form-item label="岗位编号">
          <el-input v-model="formLabelAlign1.postNo"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upCompanyId"
            placeholder="请选择"
            @change="FindDept"
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级部门">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upDeptmentId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in deptdata1"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addItem">保存并新增</el-button>
        <el-button type="primary" @click="addItem1">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改岗位" :visible.sync="dialogVisible1" width="20%">
      <el-form label-width="80px" :model="formLabelAlign1">
        <el-form-item label="岗位名称">
          <el-input v-model="formLabelAlign1.postName"></el-input>
        </el-form-item>
        <el-form-item label="岗位编号">
          <el-input v-model="formLabelAlign1.postNo"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upCompanyId"
            placeholder="请选择"
            @change="FindDept"
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级部门">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upDeptmentId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in deptdata1"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exidItem1">确 定</el-button>
        <el-button @click="dialogVisible1 = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from '../../api/http'

export default {
  data() {
    return {
      elementHeight: 0,
      Companydata1: this.$store.state.companyList,
      Cpy: '',
      deptdata: [],
      deptdata1: [],
      tableData: [],
      formLabelAlign1: {
        id: 0,
        postNo: '',
        postName: '',
        upCompanyId: '',
        upDeptmentId: '',
        isDelete: 0,
      },
      dialogVisible: false,
      dialogVisible1: false,
      defaultProps1: {
        children: 'deptlist',
        label: 'deptmentName',
      },
      currentKey:'',
    }
  },
  created(){
    this.Cpy = JSON.parse(sessionStorage.getItem('userInfo')).upCompanyId;
    this.currentKey = JSON.parse(sessionStorage.getItem('userInfo')).upDeptmentId;
    this.getDept(this.Cpy);
  },
  mounted() {
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52)
      })
    },
    getDept(e) {
      get('/api/Department/GetTree?CompId=' + e).then((res) => {
        this.deptdata = res.data
        this.tableData = []
        this.deptdata.forEach(item =>{
          if(item.id == this.currentKey){
            this.handleNodeClick1(item)
          }
        })
      })
    },
    toAdd() {
      this.formLabelAlign1.id = 0
      this.formLabelAlign1.postNo = ''
      this.formLabelAlign1.postName = ''
      this.formLabelAlign1.upCompanyId = ''
      this.formLabelAlign1.upDeptmentId = ''
      this.dialogVisible = true
    },
    handleNodeClick1(e) {
      console.log(e);
      get('/api/Post?CompId=' + this.Cpy + '&DeptId=' + e.id).then((res) => {
        this.tableData = res.data
        this.$refs.tree.setCurrentKey(e.id)
      })
    },
    FindDept(e) {
      get('/api/Department/GetNoTree?CompId=' + e).then((res) => {
        this.deptdata1 = res.data
      })
    },
    addItem() {
      post('/api/Post', this.formLabelAlign1)
        .then((res) => {
          this.$message({
            type: 'success',
            message: res.message,
          })
          //this.toAdd();
        })
        .then(() => {
          get(
            '/api/Post?CompId=' +
              this.formLabelAlign1.upCompanyId +
              '&DeptId=' +
              this.formLabelAlign1.upDeptmentId
          ).then((res) => {
            this.tableData = res.data
          })
        })
      this.toAdd()
    },
    addItem1() {
      post('/api/Post', this.formLabelAlign1)
        .then((res) => {
          this.$message({
            type: 'success',
            message: res.message,
          })
          //this.toAdd();
        })
        .then(() => {
          get(
            '/api/Post?CompId=' +
              this.formLabelAlign1.upCompanyId +
              '&DeptId=' +
              this.formLabelAlign1.upDeptmentId
          ).then((res) => {
            this.tableData = res.data
          })
        })
      this.dialogVisible = false
    },
    deleteItem(e) {
      this.$confirm('此操作将永久删岗位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(e)
          deleteMethod('/api/Post?Id=' + e.id)
            .then((res) => {
              this.$message({
                type: 'success',
                message: res.message,
              })
            })
            .then(() => {
              get(
                '/api/Post?CompId=' +
                  e.upCompanyId +
                  '&DeptId=' +
                  e.upDeptmentId
              ).then((res) => {
                console.log(res)
                this.tableData = res.data
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    editItem(e) {
      this.FindDept(e.upCompanyId)
      this.formLabelAlign1 = e
      this.dialogVisible1 = true
    },
    exidItem1() {
      put('/api/Post', this.formLabelAlign1).then((res) => {
        this.$message({
          type: 'success',
          message: res.message,
        })
      })
      this.dialogVisible1 = false
    },
  },
}
</script>

<style scoped>
@import '../../assets/style/Post.css';
</style>
